<template>
	<div id="project-list">
		<Header2022 :navbg='true'></Header2022>
		<div class="title">Smart0项目</div>
		<div class="house-list" v-if="houseList && houseList.length>0">
			<div class="house-item" v-for="(item,index) in houseList" :key="index">
				<div class="image">
					<img :src="item.image_max" alt="">
				</div>
				<div class="reserve">
					<i>{{item.name}}</i>
					<el-button class="blackbtn" @click="reserveTap(item.category_id)">立即预定</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Header2022 from '@/components/header2022.vue'
export default {
	components: {
		Header2022,
	},
	data() {
		return {
			houseList: [],
		}
	},
	computed: {
		...mapState([ 'houseApi']),
	},
	watch: {
		'$route': {
			handler: function(to) {
				if (to.name == "projectList" && to.params.id) {
					this.gethouseList(to.params.id);
				}
			},
			immediate: true
		},
	},
	methods: {
		reserveTap(id){
			this.$router.push({
				name: 'houseConfig',
				params: {
					id,
				}
			});
		},
		gethouseList(area_id) {
			this.$axios.get(this.houseApi + 'house/area_house_project_list', {
					params: {
						area_id,
						type:'smart0'
					}
				})
				.then(res => {
					this.houseList = res.data.data.homes_projects;
				})
				.catch(error => {
					this.$message.error(error.response.data.message);
				});
		},
	}
}
</script>
<style lang="scss">
#project-list{
	width: 100%;
	padding: 150px 5% 0;
	box-sizing: border-box;
	.title{
		font-size: 44px;
		line-height:44px;
		margin-bottom: 15px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 60px;
		
	}
	.house-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	
		.house-item {
			width: 26%;
			margin: 0 2% 40px;
			.image{
				img {
					width: 100%;
					border-radius: 8px;
					height: 23.8vh;
				}
			}
			
			.reserve {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 20px;
				i {
					font-size: 24px;
				}
				.el-button {
					width: 28%;
					height: 46px;
					background: linear-gradient(90deg, #01CDF2 0%, #0FE993 100%);
					border-radius: 24px;
					font-size: 18px;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
	
			@media only screen and (max-width:1680px) {
	
				.reserve {
					i {
						font-size: 22px;
					}
	
					.el-button {
						height: 42px;
						border-radius: 24px;
						font-size: 18px;
					}
				}
			}
	
			@media only screen and (max-width:1440px) {
	
				.reserve {
					i {
						font-size: 20px;
					}
	
					.el-button {
						height: 36px;
						border-radius: 24px;
						font-size: 14px;
					}
				}
			}
	
			@media only screen and (max-width:1280px) {
				.reserve {
					i {
						font-size: 16px;
					}
	
					.el-button {
						height: 34px;
						border-radius: 24px;
						font-size: 12px;
					}
				}
			}
		}
	}
}
</style>